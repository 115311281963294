<template>
  <div class="app-login">
    <div id="banner">
      <div>
        <img width="250px" src="@/assets/img/insideBrasil.png" alt="" />
      </div>
      <div class="video-wrapper">
        <video
          autoplay
          loop
          controls
          :src="videoURL"
          ref="videoRef"
          class="video video-background"
          muted
        ></video>
      </div>
    </div>
    <div id="login">
      <div id="auth-data">
        <div class="heading">
          <span>Bem vindo a <strong>Inside Brasil</strong></span>
        </div>

        <div ref="form" autocomplete="off" class="form">
          <text-field
            id="email"
            label="E-mail"
            v-model="user.email"
            placeholder="Digite seu e-mail"
            type="e-mail"
          />

          <text-field
            id="pass"
            label="Senha"
            v-model="user.password"
            placeholder="Digite sua senha"
            type="password"
            @onEnter="signin"
          />

          <span class="link">
            <router-link to="/retrieve-account">
              Esqueci minha senha
            </router-link>
          </span>

          <custom-button
            color="primary"
            @click="signin"
            :loading="loading"
            :disabled="!isValidLogin"
            text="Entrar"
          >
            <div slot="icon">
              <span class="material-icons material-icons-outlined">
                arrow_forward
              </span>
            </div>
          </custom-button>
          <custom-button
            outlined
            color="primary"
            @click="$router.push({ name: 'auth-data' })"
            text="Cadastre-se"
          ></custom-button>

          <div class="footer-login">
            <span class="link">
              Nossos
              <a
                target="_blank"
                href="https://ilumeo-brasil-com-br.s3.sa-east-1.amazonaws.com/termos-e-condicoes-de-uso-inside+brasil.pdf"
              >
                Termos de Uso
              </a>
              e
              <a
                target="_blank"
                href="https://home.insidebrasil.net/policy"
              >
                Políticas de Privacidade
              </a>
            </span>

            <span class="link">
              <a target="_blank" href="https://home.insidebrasil.net/faq">
                Confira as perguntas mais frequentes
              </a>
            </span>

            <span class="link">
              <router-link to="/resend-email">
                Reenviar e-mail de confirmação
              </router-link>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import auth from "@/api/services/auth";
import CustomButton from "@/components/Button";
import TextField from "@/components/Form/TextField";
export default {
  components: {
    CustomButton,
    TextField,
  },
  mounted() {
    localStorage.clear();
    this.videoStart();
    // this.$refs.videoRef.play();
  },

  data: () => ({
    user: {
      email: "",
      password: "",
    },
    loading: false,
    videoURL:
      "https://s3-sa-east-1.amazonaws.com/ilumeo-brasil-com-br/Inside_Brasil.mp4",
  }),
  computed: {
    isValidLogin() {
      let valid;
      if (this.user.email && this.user.password) {
        valid = true;
      } else {
        valid = false;
      }
      return valid;
    },
  },
  methods: {
    async signin() {
      this.loading = true;
      try {
        const response = await auth.signin(this.user);

        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user", JSON.stringify(response.data.user));

        this.loading = false;

        if (!response.data.user.name) {
          await this.$router.push({ name: "user-data" });
        } else {
          await this.$router.push({ name: "main" });
        }
      } catch (error) {
        this.$toast.error("E-mail ou senha incorretos");
        this.loading = false;
      }
    },
    videoStart() {
      let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      let video = document.querySelector(".video");
      if (isSafari && video) {
        setTimeout(function () {
          // werid fix for safari
          document.querySelector(".video-wrapper").innerHTML = video.outerHTML;
        }, 100);
      }
    },
  },
};
</script>

<style>
.app-login {
  display: grid;
  grid: "banner form" 100vh / 2fr 1fr;
  height: 100vh;
  background: #e7e7e7;
}
#login {
  width: 100%;
  grid-area: form;
  padding: 20px;
  overflow: auto;
  box-sizing: border-box;
}
#banner {
  width: 100%;
  grid-area: banner;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background-color: #221e1e;
}
.form {
  display: flex;
  flex-direction: column;
}
.footer-login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 1em;
}
strong:hover,
.link a:hover {
  cursor: pointer;
  color: #7367f0;
}
#login .error span {
  -webkit-font-smoothing: antialiased;
  font-weight: normal;
  padding: 0;
  border: 0;
  background: transparent;
  color: #f64e60;
  font-size: 13px;
  line-height: 1.5;
  margin: 5px 0px;
  list-style: disc;
  display: block;

  margin-top: 0;
  padding: 5px 10px;
  border-radius: 4px;
  background: #ffe2e5;
}
.link,
.link a {
  padding: 0.25rem 0;
  font-size: 0.8rem;
}
.video-background {
  display: block;
  width: 80%;
  margin: 0 auto;
}
@media only screen and (max-width: 768px) {
  .app-login {
    grid: "banner" "form" / 1fr;
    height: fit-content;
  }
  #banner {
    height: 30vh;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .video-background {
    display: none;
  }
  #login {
    overflow: initial;
  }
}
</style>
